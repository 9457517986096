// FlightContext.js
import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types' // Import PropTypes
const FlightContext = createContext()

export function FlightProvider({ children }) {
  const [flightsContextData, setFlightsContextData] = useState({ selectedFlightBar: -1 })

  return (
    <FlightContext.Provider value={{ flightsContextData, setFlightsContextData }}>
      {children}
    </FlightContext.Provider>
  )
}
FlightProvider.propTypes = {
  children: PropTypes.node.isRequired, // Validate that children is required and should be a node
}
export function useFlightData() {
  return useContext(FlightContext)
}
