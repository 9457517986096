import axios from 'axios';
import { API_ENDPOINTS } from './api';

const refreshAccessToken = async () => {
    try {
        const response = await axios.post(API_ENDPOINTS.REFRESH_TOKEN, {
            token: localStorage.getItem('access_token'),
        });
        const { token: newAccessToken } = response.data;

        if (!newAccessToken) return

        localStorage.setItem('access_token', newAccessToken);

        return newAccessToken;
    } catch (error) {
        console.error('Failed to refresh access token:', error);
        throw error;
    }
};

const excludedEndpoints = ['/api/login', '/api/register', '/api/refresh', '/api/logout'];


axios.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if ((error.response?.data?.message === "Token has expired" || error?.response.status === 401) && !excludedEndpoints.some(endpoint => originalRequest.url.includes(endpoint))) {
            originalRequest._retry = true;

            try {
                const newAccessToken = await refreshAccessToken();
                if (!newAccessToken) {
                    console.error('Failed to obtain new access token');
                    window.location.href = '/logout';
                    return;
                }

                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return axios(originalRequest);
            } catch (refreshError) {
                console.error('Unable to refresh token:', refreshError);
                // authService.logout()
                setTimeout(() => {
                    window.location.href = '/logout';
                }, 4000)
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);
