import React from 'react'
import './FitsAirLoader.css'
import logo from './../../images/logo.png'
import { GridLoader } from 'react-spinners'
function FitsAirLoader(props) {
  return (
    <>
      {/* <div className="fitsairLoader">
        <div className="Loader"></div>
        <div className="ImageContainer">
          <img src={logo} alt="Your Logo" />
        </div>
      </div> */}
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed', // Use 'fixed' instead of 'absolute'
          top: 0,
          left: 0,
          backgroundColor: 'rgba(5, 6, 73, 0.5)',
          zIndex: '3000', // Specify '3000' as a string
        }}
      >
        <GridLoader color="#ffffff" />
      </div>
    </>
  )
}

export default FitsAirLoader
