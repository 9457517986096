import React, { Component, Suspense } from 'react'
import { BrowserRouter, HashRouter, Route, Routes, useLocation } from 'react-router-dom'
import './scss/style.scss'
import './App.css'
import { AuthProvider, useAuth } from './AuthContext' // Import your AuthProvider
import PropTypes from 'prop-types'
import { FlightContext, FlightProvider } from './FlightContext'
import FitsAirLoader from './components/Loader/FitsAirLoader'
import { ApolloProvider } from '@apollo/client'
import client from './apollo/client'
const loading = (
  // <div className="pt-3 text-center">
  //   <div className="sk-spinner sk-spinner-pulse"></div>
  // </div>
  <FitsAirLoader />
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const AirCraftData = React.lazy(() => import('./views/masterdata/flight/AirCraftData'))
const AirPortData = React.lazy(() => import('./views/masterdata/flight/AirPortData'))
const AirCraftModels = React.lazy(() => import('./views/masterdata/flight/AirCraftModels'))
const CrewCategory = React.lazy(() => import('./views/masterdata/crew/CrewCategory'))
const CrewInformation = React.lazy(() => import('./views/masterdata/crew/CrewInformation_dep2'))
const SchedulePeriods = React.lazy(() => import('./views/masterdata/crew/SchedulePeriods'))
const MaintenanceTypes = React.lazy(() => import('./views/masterdata/crew/MaintenanceTypes'))
const Checks = React.lazy(() => import('./views/masterdata/crew/Checks'))

class AppAuth extends Component {
  render() {
    const { isAuthenticated } = this.props

    console.log(['isAuthenticated', isAuthenticated])
    return (
      <ApolloProvider client={client}>
        <AuthProvider>
          <FlightProvider>
            <BrowserRouter>
              <Suspense fallback={loading}>
                <Routes>
                  <Route
                    exact
                    path="/"
                    name="Login Page"
                    element={isAuthenticated ? <DefaultLayout /> : <Login />}
                  />
                  <Route exact path="/register" name="Register Page" element={<Register />} />
                  <Route exact path="/404" name="Page 404" element={<Page404 />} />
                  <Route exact path="/500" name="Page 500" element={<Page500 />} />
                  <Route exact path="/logout" name="Page 500" element={<Login />} />
                  <Route
                    path="*"
                    name="Home"
                    element={isAuthenticated ? <DefaultLayout /> : <Login />}
                  />
                </Routes>
              </Suspense>
            </BrowserRouter>
          </FlightProvider>
        </AuthProvider>
      </ApolloProvider>
    )
  }
}

// export default App
const App = () => {
  const { isAuthenticated } = useAuth()

  return <AppAuth isAuthenticated={isAuthenticated} />
}

AppAuth.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
}
export default App
