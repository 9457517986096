import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_ENDPOINTS } from './api';

const initialState = {
  isAuthenticated: false,
  user: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const storedAuthState = localStorage.getItem('authState');
    if (storedAuthState) {
      const parsedAuthState = JSON.parse(storedAuthState);
      dispatch({ type: 'LOGIN', payload: parsedAuthState.user });
    }
  }, []);

  const login = (user) => {
    dispatch({ type: 'LOGIN', payload: user });
    localStorage.setItem('authState', JSON.stringify({ user }));
    localStorage.setItem('privileges', JSON.stringify(user.usersData));
  };

  const logout = () => {
      localStorage.removeItem('authState');
      localStorage.removeItem('privileges');
      localStorage.removeItem('access_token');
      dispatch({ type: 'LOGOUT' });
    // })
  };

  return (
    <AuthContext.Provider value={{ ...state, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const authService = {
  isAuthenticated() {
    const authState = localStorage.getItem('authState');
    return authState ? JSON.parse(authState).user !== null : false;
  },

  login(user) {
    localStorage.setItem('authState', JSON.stringify({ user }));
    console.log('User logged in');
  },

  logout() {
    // axios.post(API_ENDPOINTS.LOGOUT).then(() => {
      localStorage.removeItem('authState');
      localStorage.removeItem('privileges');
      localStorage.removeItem('access_token');
      initialState.isAuthenticated = false;
    // })
  }
}
